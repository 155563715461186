.scale-up-center {
  -webkit-animation: scale-up-center 0.7s ease-in-out infinite alternate-reverse
    both;
  animation: scale-up-center 0.7s ease-in-out infinite alternate-reverse both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-2-17 15:52:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
